/*----------
commande to add rout js withe expose
php bin/console fos:js-routing:dump --format=json --target=public/js/fos_js_routes.json
--------------
yarn encore dev --watch
--------------
ngrok http http://127.0.0.1:8000

-------------
JWT_KEY='aVerySecretKey' ADDR='127.0.0.1:3000' ALLOW_ANONYMOUS=1 CORS_ALLOWED_ORIGINS="http://127.0.0.1:8000" ./mercure/mercure

ps aux
kill -9 id

node -v : 12.22.9
npm -v: 6.14.15


 composer require stof/doctrine-extensions-bundle v1.3.0
--------*/



var $ = require('jquery');
window.$ = $;
window.jQuery = $;
/*if(screen.width <576){
    $('#carouselExampleIndicators').remove();
    $('#img-seller').remove();
}*/
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
//users visits

require('./router');

/*require('./lib/jquery-ui.min');*/
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-slide');
require('jquery-ui/ui/widgets/autocomplete');

/*require('bootstrap');*/
require('bootstrap/js/src/alert');
require('bootstrap/js/src/carousel');
require('bootstrap/js/src/collapse');
require('bootstrap/js/src/dropdown');
require('bootstrap/js/src/modal');
require('bootstrap/js/src/tooltip');

require('./lib/notification');
require('./lib/markAsVisited');
require('./lib/pusher.min');
require('./lib/notify_pusher');
require('./lib/cart');
/*require('./lib/cookieconsent');*/


//-----------React.js
/*import Translator from 'bazinga-translator';*/
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
$(document).ready(function() {
        $('#google-login').addClass('gbtn');
        //new camion bar
        let new_truck_close = localStorage.getItem('new_truck_close');
        let bar = document.getElementsByClassName('new-truck-bar');
        let btn_new_truck_bar_close = document.getElementById('btn-new-truck-bar-close');
        if(bar.length > 0){
            if (new_truck_close === null){
                bar[0].style.display='block';
            }
            btn_new_truck_bar_close.addEventListener('click',function (){
                bar[0].style.display='none';
                localStorage.setItem('new_truck_close',true);
            })
        }

});

/*$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});*/
//message flash fide out
window.setTimeout(function() {
    $('.message-flash').toggle("slide", { direction: "right" }, 50);
}, 5000)


$(document).ready(function (){
    $('[data-toggle="tooltip"]').tooltip({
        container : 'body'
    })
})
//----------------------------------------------------------------------



$(window).scroll(function(){
    if($(window).scrollTop()<40){
        $('#top-header').show();
        $('header').css('top','40px');
    }
    else
    {
        $('#top-header').hide();
        $('header').css('top','0px');
    }
});

$("#app_user_registration_type").change(function(){
    if(this.value == 1){
        $("#user-pro").css('display','block')
        $("#user-user").css('display','none')
    }
    if(this.value == 2){
        $("#user-pro").css('display','none')
        $("#user-user").css('display','block')
    }

});

// Small menu
$('#menu-small').click(function() {
    if($('#menu').css('display')=='none')
    {
        $('#menu').show();
    }
    else
    {
        $('#menu').hide();
    }
});

/*

window.fbAsyncInit = function() {
    FB.init({
        appId      : '267832865612859',
        xfbml      : true,
        version    : 'v17.0'
    });
    FB.AppEvents.logPageView();
};

(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
*/
//lazyload for background
//add simple support for background images:
/*document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});
//or add AJAX loading
//<div class="lazyload" data-ajax="my-url.html"></div>

$(document).on('lazybeforeunveil', function(){
    var ajax = $(e.target).data('ajax');
    if(ajax){
        $(e.target).load(ajax);
    }
});*/

//js lazy loding à main
document.addEventListener("DOMContentLoaded", function() {
    var lazyloadImages;

    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(".lazy");
        var imageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function(image) {
            imageObserver.observe(image);
        });
    } else {
        var lazyloadThrottleTimeout;
        lazyloadImages = document.querySelectorAll(".lazy");

        function lazyload () {
            if(lazyloadThrottleTimeout) {
                clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function() {
                var scrollTop = window.pageYOffset;
                lazyloadImages.forEach(function(img) {
                    if(img.offsetTop < (window.innerHeight + scrollTop)) {
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                    }
                });
                if(lazyloadImages.length === 0) {
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }
})

//chat bot

const chatIcon = document.getElementById("chat-icon");
const close_chat = document.getElementById("close-chat");
const closeHelpBox = document.getElementById("closeHelpBox");
const chatContainer = document.getElementById("chat-container");
const chatBox = document.getElementById("chat-box");
const userInput = document.getElementById("user-input");
const sendButton = document.getElementById("send-button");
const suggestion_btns = document.getElementsByClassName("chatbot-suggestion");



const observer = new MutationObserver(function(mutationsList, observer) {
    const suggestion_btns = document.getElementsByClassName("chatbot-suggestion");

    for (let i = 0; i < suggestion_btns.length; i++) {
        suggestion_btns[i].addEventListener("click", function() {
            const buttonText = this.textContent || this.innerText;
            if (!this.hasAttribute('data-clicked')) {
                this.setAttribute('data-clicked', 'true');
                addMessage(buttonText, 'user');
                userInput.value = buttonText;
                handleUserInput()
            }
        });
    }
});
observer.observe(document.body, { childList: true, subtree: true });

chatIcon.addEventListener("click", () => {
    chatContainer.classList.toggle("hidden");
});
close_chat.addEventListener("click", () => {
    chatContainer.classList.toggle("hidden");
});
if (localStorage.getItem("closeHelpBox") === "true") {
    const helpBox = document.getElementById('help-box');
    helpBox.style.display = "none";
}
closeHelpBox.addEventListener("click", () => {
    const helpBox = document.getElementById('help-box');
    helpBox.style.display = 'none';
    localStorage.setItem("closeHelpBox", "true");
});

function addMessage(content, type) {
    const chatBox = document.querySelector(".chat-box");
    const messageElement = document.createElement("div");
    messageElement.classList.add(type === "sender" ? "user-message" : "bot-message");
    const imageElement = document.createElement("img");
    imageElement.src = type === "sender"
        ? "/assets/images/profile/user-avatar-small.png"
        : "/assets/images/logo/chatbot.png";
    imageElement.alt = type === "sender" ? "User" : "Bot";
    imageElement.classList.add("message-avatar");

    const messageText = document.createElement("p");
    messageText.innerHTML = content;

    if (type === "sender") {
        messageElement.appendChild(messageText);
        messageElement.appendChild(imageElement);
    } else {
        messageElement.appendChild(imageElement);
        messageElement.appendChild(messageText);
    }
    /*messageElement.innerHTML = message;*/
    chatBox.appendChild(messageElement);
    chatBox.scrollTop = chatBox.scrollHeight;

}
//reload the history
window.onload = function () {
    const history = localStorage.getItem('history');
    if (history) {
        const messages = history.split('§@');
        messages.forEach((msg) => {
            if (msg.trim() !== "") {
                const type = msg.startsWith("user:") ? "sender" : "receiver";
                const content = msg.replace(/^user:|^bot:/, "").trim();
                addMessage(content, type);
            }
        });
    }
};
const reference = localStorage.getItem('chat_reference');
if(!reference){
    localStorage.setItem('chat_reference', generateReferenceCode())
}
function handleUserInput() {
    const userMessage = userInput.value.trim();
    if (userMessage === "") return;

    addMessage(userMessage, "sender");
    saveToHistory(`user:${userMessage}`);
    userInput.value = "";

    let data = {'message': userMessage,'reference':reference};
    let url = Routing.generate('chatbot_answer' + '.' + document.documentElement.lang);
    $.ajax({
        method: "POST",
        dataType: "json",
        url: url,
        data: data,
    }).done(function (response) {
        let answer = response['answer'];
        saveToHistory(`bot:${answer}`);
        setTimeout(() => addMessage(answer, "receiver"), 500);
    }).fail(function(jxh,textmsg,errorThrown){
        alert(Translator.trans('Something went wrong!'));
    });

}

function saveToHistory(message) {
    let history = localStorage.getItem('history') || "";
    history += message + '§@';
    localStorage.setItem('history', history);
}


sendButton.addEventListener("click", handleUserInput);

userInput.addEventListener("keypress", (e) => {
    if (e.key === "Enter") {
        handleUserInput();
    }
});function closeChat() {
    const chatbotHead = document.querySelector('.chatbot-head');
    chatbotHead.style.display = 'none';
}
function generateReferenceCode(length = 16) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}

function triggerShake() {
    chatIcon.classList.add('shake'); // إضافة الحركة
    setTimeout(() => {
        chatIcon.classList.remove('shake'); // إزالة الحركة بعد نصف ثانية (مدة الحركة)
    }, 2000); // مطابقة مع مدة الـ animation
}

// تكرار الحركة كل 10 ثوانٍ
setInterval(triggerShake, 7000);

